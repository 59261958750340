<template>
  <div class="layout_container">
    <div class="headers_glass"></div>
    <div class="headers">
      <div class="headers_icon_container">
        <div class="chenqiao_icon">
          <img :src="logo2" alt="" />
          <span>广东彬茂新型建筑材料有限公司</span>
        </div>
        <div class="zhaohuatai_logo">
          <!-- <img :src="logo2" alt="" /> -->
        </div>
      </div>

      <div class="headers_menu">
        <ul class="headers_menu_container">
          <li
            :class="
              active === v.path
                ? 'headers_menu_item_active'
                : 'headers_menu_item'
            "
            v-for="(v, i) in route"
            :key="i"
            @click="selectTab(v)"
          >
            {{ v.meta.title }}
          </li>
        </ul>
      </div>
    </div>
    <router-view class="main_container" />
    <div class="footer">
      <div class="footer_info">
        <div class="footer_info_left">
          <span>客服热线: 15363426273</span>
          <span>联系邮箱: 15363426273@163.com</span>
          <span>公司地址: 广东省佛山市南海区狮山镇松岗松夏工业园工业大道东13号广东彬茂新型建筑材料有限公司</span>
          <span>公司官网: <a href="https://www.binmao.top">https://www.binmao.top</a></span>
          <span class="footer_info_left_item">{{'© 2023-'+ now + ' 广东彬茂新型建筑材料有限公司 版权所有 ICP证:'}} <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2023063595号</a></span>
        </div>
        <div class="footer_info_right">
          <div class="footer_info_right_item">
            <div class="footer_info_right_item_img">
              <img :src="rcqr" alt="">
            </div>
            <div class="footer_info_right_item_txt">公众账号</div>
          </div>

          <div class="footer_info_right_item">
            <div class="footer_info_right_item_img">
              <img :src="kfqr" alt="">
            </div>
            <div class="footer_info_right_item_txt">客服微信</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bmqr, kfqr, zhaohuataiLogo } from "@/mock/data";
import routerArr from "@/router/routerArr";

export default {
  components: {},
  data() {
    return {
      active: "/",
      logo2: zhaohuataiLogo,
      route: routerArr,
      rcqr: bmqr,
      kfqr: kfqr,
      now: null
    };
  },
  methods: {
    selectTab(v) {
      //@ts-ignore
      if (this.active !== v.path) {
        //@ts-ignore
        this.active = v.path;
        this.$router.push(this.active);
      }
    },
  },
  mounted() {
    this.active = this.$route.fullPath;
    this.now = new Date().getFullYear()
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/global.scss";

.layout_container {
  width: 100%;
  height: 100%;
  @include hiddenSroll();
  position: relative;
  .headers {
    width: 100%;
    height: 104px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    color: #000;
    @include flex(column, center, center);
          // position: relative;
    .headers_icon_container {
      flex: 0 0 56px;
      width: 100%;
      @include flex(row, center, center);
      .chenqiao_icon {
        flex: 1;
        height: 28px;
        @include gew(25px);
        
        @include flex(row, center, center);
        color: #fff;
        img {
          height: 100%;
          margin-right: 10px;
        }
      }

      .zhaohuatai_logo {
        position: absolute;
        width: 44px;
        height: 42px;
        top: 4px;
        right: 465px;
        z-index: 99;
        img {
          height: 100%;
        }
      }
    }
    .headers_menu {
      flex: 1;
      width: 100%;
      border-top: 1px solid #fff;
      @include flex(row, center, center);
      .headers_menu_container {
        flex: 1;
        height: 100%;
        list-style: none;
        color: #fff;
        @include flex(row, center, center);
        .headers_menu_item,
        .headers_menu_item_active {
          margin-right: 105px;
          @include gew(16px);
          position: relative;
          cursor: pointer;
        }
        .headers_menu_item_active {
          &::before {
            content: "";
            width: 100%;
            height: 1px;
            background-color: #fff;
            position: absolute;
            bottom: -10px;
          }
        }
      }
    }
  }
  .headers_glass {
    width: 100%;
    height: 104px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    backdrop-filter: blur(30px);
    background-color: #fff;
    opacity: 0.2;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
  }

  .main_container {
    width: 100%;
    height: auto;
  }

  .footer {
    width: 100%;
    height: 295px;
    position: relative;
    .footer_info {
      position: absolute;
      top: 94px;
      left: 450px;
      right: 470px;
      bottom: 101px;
      @include flex(row, center, center);
      .footer_info_left {
        flex: 1;
        height: 100%;
        @include pfm(12px);
        color: #777;
        @include flex(column, space-between, flex-start);
      }
      .footer_info_right {
        flex: 0 0 217px;
        height: 100%;
        @include flex(row, space-between, center);
        .footer_info_right_item {
          flex: 0 0 85px;
          height: 100%;
          @include flex(column, center, center);
          .footer_info_right_item_img {
            flex: 1;
            width: 100%;
            img{
              width: 100%;
            }
            @include flex(column, center, center);
          }
          .footer_info_right_item_txt {
            flex: 0 0 30px;
            width: 100%;
            @include pfm(12px);
            color: #777;
            @include flex(column, flex-end, center);
          }
        }
      }
    }
  }
}
</style>